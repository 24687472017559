.trainingAndMatches {
  height:65%;
  padding: 2.5%;
  color:white ;
  background-color: #ad985f ;
}

.trainingAndMatches h2 {
  letter-spacing: 15px;
  font-family: 'Bebas Neue', serif;
  color:white ;
}

.trainingAndMatches .left {
  text-align: center;
}

.trainingAndMatches .right {
  padding-bottom: 2.5%;
}

  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  
  
    .trainingAndMatches {
      height:auto;
      display: inline-table;
    }
  

    
  }
  
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (max-width: 768px) {

    .trainingAndMatches {
      height:auto;
      display: inline-table;
      padding-bottom: 2.5%;
    }
  
  }
  
  @media only screen and (max-width: 1200px) {

    .trainingAndMatches {
      height:auto;
      display: inline-table;
      padding-bottom: 2.5%;
    }
  
  }