#fixtures {
    padding: 2.5%;
}

#fixtures h2 {
    text-align: center;
    font-family: 'Bebas Neue', serif;
    color:#0d4226 ;
}

@media only screen and (max-width: 600px) {
    #fixtures {
        margin-top: 20%;
    }
}

@media only screen and (max-width: 300px) {
    #fixtures {
        margin-top: 12.5%;
    }
}

@media only screen and (max-height: 800px) {
    #fixtures {
        margin-top: 7.5%;
    }
}

@media only screen and (max-height: 600px) {
    #fixtures {
        margin-top: 7.5%;
    }
}