@import url('https://fonts.googleapis.com/css?family=Bebas+Neue');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

html, body {
  background-color:#FAFAFA;
  height: 100%;
  font-family: 'Open Sans'; font-size: 24px; font-style: normal; font-variant: normal; font-weight: 100; line-height: 26.4px;
  margin: 0;
  
}
::-webkit-scrollbar {
  display:none;
}
#root{
  height: 100%;
  margin: 0;
}

.teamPhoto {
  height:100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  
}